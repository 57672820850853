export class ConstsOfMkt {



  public static  FORM_CAT: any[] = [
    {key: '1001', name : '通用', check: false},
    {key: '1002', name : '人力资源管理', check: false },
    {key: '1003', name : '财务管理', check: false },
    {key: '1004', name : '行政管理', check: false },
    {key: '1005', name : '法律与合同', check: false },
    {key: '1006', name : '项目管理', check: false },
    {key: '1007', name : '市场营销与销售', check: false },
    {key: '1009', name : '客户服务与关系', check: false },
    {key: '1010', name : '物流与供应链', check: false },
    {key: '1011', name : '资产设备管理', check: false },
    {key: '1012', name : '产品与质量控制', check: false },
    {key: '1013', name : '教育与学术', check: false },
    {key: '1014', name : '健康与医疗保健', check: false },
    {key: '1015', name : '体育与健身', check: false },
    {key: '1016', name : '房地产与建筑', check: false },
    {key: '1017', name : '汽车与交通', check: false },
    {key: '1018', name : '会员管理', check: false },
    {key: '1019', name : '媒体与传播', check: false },
    {key: '1048', name : '社区服务与志愿者', check: false },
    {key: '1042', name : '金融与财务', check: false },
    {key: '1020', name : '餐饮与服务业', check: false },

    {key: '1008', name : 'IT与技术', check: false },
    {key: '1021', name : '文化与艺术', check: false },
    {key: '1022', name : '科学与研究', check: false },
    {key: '1023', name : '酒店与旅游', check: false },
    {key: '1024', name : '投资与资产管理', check: false },
    {key: '1025', name : '环境与保护', check: false },
    {key: '1026', name : '网络与数字服务', check: false },
    {key: '1027', name : '艺术与设计', check: false },
    {key: '1029', name : '农业与农村发展', check: false },
    {key: '1030', name : '职业与职场发展', check: false },
    {key: '1031', name : '娱乐与活动', check: false },
    {key: '1032', name : '能源与可再生能源', check: false },
    {key: '1033', name : '建筑与工程', check: false },
    {key: '1034', name : '家庭与个人', check: false },
    {key: '1035', name : '新闻与媒体', check: false },
    {key: '1036', name : '技术与信息安全', check: false },
    {key: '1037', name : '自然与环境保护', check: false },
    {key: '1038', name : '消费者服务与投诉', check: false },
    {key: '1039', name : '创意与设计', check: false },
    {key: '1040', name : '安全与紧急响应', check: false },
    {key: '1041', name : '军事与国防', check: false },
    {key: '1043', name : '家居与装饰', check: false },
    {key: '1044', name : '运动与比赛', check: false },
    {key: '1045', name : '玩具与游戏', check: false },
    {key: '1046', name : '动物与宠物服务', check: false },
    {key: '1049', name : '科技与创新', check: false },
    {key: '1050', name : '网络与数字媒体', check: false },
    {key: '1051', name : '政府与公共服务', check: false },
    {key: '1052', name : '非营利组织与慈善', check: false },
    {key: '1053', name : '人文与社科研究', check: false },
    {key: '1054', name : '社交与人际关系', check: false },

  ];


  public static  APP_CAT: any[] = [
    {key: '1001', name : '个人生活', check: false},
    {key: '1002', name : '人力资源管理', check: false },
    {key: '1003', name : '财务管理', check: false },
    {key: '1004', name : '行政管理', check: false },
    {key: '1005', name : '法律与合同', check: false },
    {key: '1006', name : '项目管理', check: false },
    {key: '1007', name : '市场营销与销售', check: false },
    {key: '1009', name : '客户服务与关系', check: false },
    {key: '1010', name : '物流与供应链', check: false },
    {key: '1011', name : '资产设备管理', check: false },
    {key: '1012', name : '产品与质量控制', check: false },
    {key: '1013', name : '教育与学术', check: false },
    {key: '1014', name : '健康与医疗保健', check: false },
    {key: '1015', name : '体育与健身', check: false },
    {key: '1016', name : '房地产与建筑', check: false },
    {key: '1017', name : '汽车与交通', check: false },
    {key: '1018', name : '会员管理', check: false },
    {key: '1019', name : '媒体与传播', check: false },
    {key: '1048', name : '社区服务与志愿者', check: false },
    {key: '1042', name : '金融与财务', check: false },
    {key: '1020', name : '餐饮与服务业', check: false },

    {key: '1008', name : 'IT与技术', check: false },
    {key: '1021', name : '文化与艺术', check: false },
    {key: '1022', name : '科学与研究', check: false },
    {key: '1023', name : '酒店与旅游', check: false },
    {key: '1024', name : '投资与资产管理', check: false },
    {key: '1025', name : '环境与保护', check: false },
    {key: '1026', name : '网络与数字服务', check: false },
    {key: '1027', name : '艺术与设计', check: false },
    {key: '1029', name : '农业与农村发展', check: false },
    {key: '1030', name : '职业与职场发展', check: false },
    {key: '1031', name : '娱乐与活动', check: false },
    {key: '1032', name : '能源与可再生能源', check: false },
    {key: '1033', name : '建筑与工程', check: false },
    {key: '1034', name : '家庭与个人', check: false },
    {key: '1035', name : '新闻与媒体', check: false },
    {key: '1036', name : '技术与信息安全', check: false },
    {key: '1037', name : '自然与环境保护', check: false },
    {key: '1038', name : '消费者服务与投诉', check: false },
    {key: '1039', name : '创意与设计', check: false },
    {key: '1040', name : '安全与紧急响应', check: false },
    {key: '1041', name : '军事与国防', check: false },
    {key: '1043', name : '家居与装饰', check: false },
    {key: '1044', name : '运动与比赛', check: false },
    {key: '1045', name : '玩具与游戏', check: false },
    {key: '1046', name : '动物与宠物服务', check: false },
    {key: '1047', name : '社会福利与慈善', check: false },
    {key: '1049', name : '科技与创新', check: false },
    {key: '1050', name : '网络与数字媒体', check: false },
    {key: '1051', name : '政府与公共服务', check: false },
    {key: '1052', name : '非营利组织与慈善', check: false },
    {key: '1053', name : '人文与社科研究', check: false },
    {key: '1054', name : '社交与人际关系', check: false },

  ];


  public static  FORM_SORT_TYPE: any[] = [
    {key: '101', name : '最多使用' },
    {key: '102', name : '最新上传' },
    {key: '103', name : '最新更新' },
  ];


  public static  FORM_VERSION: any[] = [
    {key: '101', name : 'V1' },
    {key: '102', name : 'V2' },
    {key: '103', name : 'V3' },
    {key: '104', name : 'V4' },
    {key: '105', name : 'V5' },
    {key: '106', name : 'V6' },
    {key: '107', name : 'V7' },
    {key: '108', name : 'V8' },
    {key: '109', name : 'V9' },
    {key: '110', name : 'V10' },
    {key: '111', name : 'V11' },
    {key: '112', name : 'V12' },
    {key: '113', name : 'V13' },
    {key: '114', name : 'V14' },
    {key: '115', name : 'V15' },
    {key: '116', name : 'V16' },
    {key: '117', name : 'V17' },
    {key: '118', name : 'V18' },
    {key: '119', name : 'V19' },
    {key: '120', name : 'V20' },
  ];


  static getAvailableVersionList(currentVersion: any): any[] {
    let versionList = this.FORM_VERSION;
    if (currentVersion === null ) {
      versionList = versionList.slice(0, 1);
    } else {
      const currentVer = Number(currentVersion);
      versionList = versionList.filter(obj => Number(obj.key) > currentVer);
      versionList = versionList.slice(0, 1);
    }
    return versionList;
  }
}
