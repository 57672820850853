import {AuthSetModel} from '../services/model/AuthSetModel';

export class ConstsOfAuth {

       public static NORMAL_FORM_DEFAULT_PER_GROUP_CONFIG_ARRAY: AuthSetModel[]  = [
          {
            id: '1',
            name: '仅添加数据',
            basic: { name: '仅添加数据', description: ''},
            opt: ['add'],
            field: [],
            data: { rel: 'any', conditions: []},
            type: 'preDefine01'
          },
      {
        id: '2',
        name: '添加并管理本人数据',
        basic: { name: '添加并管理本人数据', description: ''},
        opt: ['view', 'add', 'edit' , 'copy' , 'delete'],
        field: [],
        data: { rel: 'any', conditions: [
            {
              key: 'dynamicParameter',
              type: 'user',
              label: '提交者',
              value: 'dynamicParameter',
              operation: 'eq'
            }
          ]
        },
        type: 'preDefine02'
      },
      {
        id: '3',
        name: '添加并查看全部数据',
        basic: { name: '添加并查看全部数据', description: ''},
        opt: ['view', 'add'],
          field: [],
        data: { rel: 'any', conditions: []},
        type: 'preDefine03'
      },
      {
        id: '4',
        name: '查看全部数据',
        basic: { name: '查看全部数据', description: ''},
        opt: ['view'],
          field: [],
        data: { rel: 'any', conditions: []},
        type: 'preDefine04'
      },
      {
        id: '5',
        name: '管理全部数据',
        basic: { name: '管理全部数据', description: ''},
        opt: ['view', 'add', 'edit' , 'copy' , 'delete'],
          field: [],
        data: { rel: 'any', conditions: []},
        type: 'preDefine05'
      },
      {
        id: '6',
        name: '自定义',
        basic: { name: '', description: ''},
        opt: ['view'],
          field: [],
        data: { rel: 'any', conditions: []},
        type: 'selfDefine'
      }
      ];

  public static FLOW_FORM_DEFAULT_PER_GROUP_CONFIG_ARRAY: AuthSetModel[]  = [
    {
      id: '1',
      name: '发起流程',
      basic: { name: '发起流程', description: ''},
      opt: ['add'],
      field: [],
      data: { rel: 'any', conditions: []},
      type: 'preDefine01'
    },
    {
      id: '2',
      name: '查看全部流程',
      basic: { name: '查看全部流程', description: ''},
      opt: ['view'],
      field: [],
      data: { rel: 'any', conditions: []},
      type: 'preDefine02'
    },
    {
      id: '3',
      name: '管理全部流程',
      basic: { name: '管理全部流程', description: ''},
      opt: ['view', 'add', 'edit' , 'copy' , 'delete'],
      field: [],
      data: { rel: 'any', conditions: []},
      type: 'preDefine03'
    },
    {
      id: '6',
      name: '自定义',
      basic: { name: '', description: ''},
      opt: ['view'],
      field: [],
      data: { rel: 'any', conditions: []},
      type: 'selfDefine'
    }
  ];

  public static DEF_PER_GROUP_CFG_ARY_FOR_MKT: AuthSetModel[]  = [
    {
      id: '1',
      name: '仅添加数据',
      basic: { name: '仅添加数据', description: ''},
      opt: ['add'],
      field: [],
      data: { rel: 'any', conditions: []},
      type: 'preDefine01'
    },
    {
      id: '2',
      name: '添加并管理本人数据',
      basic: { name: '添加并管理本人数据', description: ''},
      opt: ['view', 'add', 'edit' , 'copy' , 'delete'],
      field: [],
      data: { rel: 'any', conditions: [
          {
            key: 'dynamicParameter',
            type: 'user',
            label: '当前用户',
            value: 'dynamicParameter',
            operation: 'eq'
          }
        ]
      },
      type: 'preDefine02'
    },
    {
      id: '3',
      name: '添加并查看全部数据',
      basic: { name: '添加并查看全部数据', description: ''},
      opt: ['view', 'add'],
      field: [],
      data: { rel: 'any', conditions: []},
      type: 'preDefine03'
    },
    {
      id: '4',
      name: '查看全部数据',
      basic: { name: '查看全部数据', description: ''},
      opt: ['view'],
      field: [],
      data: { rel: 'any', conditions: []},
      type: 'preDefine04'
    },
    {
      id: '5',
      name: '管理全部数据',
      basic: { name: '管理全部数据', description: ''},
      opt: ['view', 'add', 'edit' , 'copy' , 'delete'],
      field: [],
      data: { rel: 'any', conditions: []},
      type: 'preDefine05'
    }
  ];
}
